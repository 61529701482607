import React from "react";
import {faFastBackward, faFastForward, faStepBackward, faStepForward} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const PagingNavigator = ({handleFastBackwardClick, handleStepBackwardClick, handleStepForwardClick, handleFastForwardClick}) => {
    {
        return (
            <React.Fragment>
                <FontAwesomeIcon transform={{size: "25"}} onClick={handleFastBackwardClick} icon={faFastBackward}
                                 className="paging-icon"/>
                <FontAwesomeIcon transform={{size: "25"}} onClick={handleStepBackwardClick} icon={faStepBackward}
                                 className="paging-icon"/>
                <FontAwesomeIcon transform={{size: "25"}} onClick={handleStepForwardClick} icon={faStepForward}
                                 className="paging-icon"/>
                <FontAwesomeIcon transform={{size: "25"}} onClick={handleFastForwardClick} icon={faFastForward}
                                 className="paging-icon"/>
            </React.Fragment>
        )
    }
}

export default PagingNavigator;