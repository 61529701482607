import "../../App.css";
import {FormGroup, Label} from "reactstrap";
import React from "react";

const FilterSearch = ({handleCheckBE, handleCheckFE}) => {
    const filterOptions = [
        {name: "ACTIVE_CASES", label: "Pouze aktivní", backendFilter: true},
        {name: "CREATED_BY_ME", label: "Mnou zadané", backendFilter: true},
        {name: "MY_TEAM_ONLY", label: "Pouze můj tým", backendFilter: false}];

    const buildItem = (item) => {
        return <Label check key={item.name} className="checkbox-wrapper mr-2">
                <input value={item.name} defaultChecked={item.name === "ACTIVE_CASES"} className="checkbox" type="checkbox" onChange={item.backendFilter ? handleCheckBE : handleCheckFE}/>
                <span className="crm">{item.label}</span>
            </Label>
    }

    return (
        <>
            <FormGroup check inline>
                {filterOptions.map(item => buildItem(item))}
            </FormGroup>
        </>
    );
};

export default FilterSearch;