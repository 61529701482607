import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip} from 'reactstrap';

const TooltipIcon = ({id, icon, color, message}) => {

    const [isOpen, setOpen] = useState(false);

    const onMouseOverHandler = () => {
        setOpen(true);
    }

    const onMouseLeaveHandler = () => {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <span id={id} className="noWrap" onMouseOver={onMouseOverHandler} onMouseLeave={onMouseLeaveHandler}>
                <FontAwesomeIcon icon={icon} color={color}/>
            </span>
            <Tooltip isOpen={isOpen} target={id} placement="top">
                {message}
            </Tooltip>  
            &nbsp; 
        </React.Fragment>
    )

}

export default TooltipIcon;