import fetchIntercept from "fetch-intercept";
import dayjs from "dayjs";


export function extractParamFromURL(param) {
    let url = new URL(window.location.href);
    return url.searchParams.get(param);
}

export function removeParamsFromUrl(params){
    let url = new URL(window.location.href);
    params.forEach(p => url.searchParams.delete(p));
    return url.href;
}

export function registerInterceptor(accessToken) {
    fetchIntercept.clear();
    fetchIntercept.register({
        request: (url, config) => {
            if (!config) {
                config = {};
            }
            if (!config.headers) {
                config.headers = {};
            }
            config.headers['Authorization'] = 'Bearer ' + accessToken;
            config.headers["web-api-key"] = process.env.REACT_APP_WEBAPI_KEY;
            return [url, config];
        }
    });
}

export const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
    );
};

export const generateFormatDate = (value, dateFormat) => {
    return dayjs(new Date(value)).format(dateFormat); //možná bude dateformat asi všude stejný
};

export const sortBy = (item, attr) => {
    return item.sort((a, b) => {
        if (a[attr] === b[attr]){
            return 0;
        } else if (a[attr] > b[attr]){
            return 1;
        } else {
            return -1;
        }
    });
};