import './App.css';
import MainPage from "./comp/app/mainPage";
import Prompt from "./comp/app/prompt";
import {AppWithAuth} from "./AppWithAuth";
import React from "react";

function App() {

    const inIframe = () => {
        try {
            return window.self !== window.top
        } catch (e) {
            return true;
        }
    };

    return (
        <>
            {
                inIframe() ?
                    <Prompt/>
                    :
                    <AppWithAuth>
                        <MainPage/>
                    </AppWithAuth>
            }

        </>
    );
}

export default App;
