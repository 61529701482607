import {
    ALERT_DANGER,
    USER_LOADED,
    USER_LOADING,
    USER_LOADING_FAILED,
    USER_LOGOUT,
    USER_ROLES_EVALUATED
} from "../core/const";
import Resource from "../core/serverResource"
import {generateUUID} from "../core/utils";
import {fireShowAlert} from "./alertActions";

export const userLoading = () => (
    {type: USER_LOADING}
);

export const userLoaded = (identity) => (
    {type: USER_LOADED, identity, session: generateUUID()}
);

const userRolesEvaluated = (userRoles) => (
    {type: USER_ROLES_EVALUATED, userRoles}
);

export const userLoadingFailed = (err) => (
    {type: USER_LOADING_FAILED, err}
);

export const userLogout = () => (
    {type: USER_LOGOUT}
);

export function fireGetLoggedUser() {
    return (dispatch) => {
        dispatch(userLoading());
        return fetch(Resource.getFocupApi() + "/user", {
            method: "GET",
            headers: {"Content-Type": "application/json" }
        }).then(Resource.checkStatus)
            .then(response => response.json())
            .then(user => {
                //TODO tohle pak smazat
                user.roles.map(r => r.toUpperCase());
                dispatch(userLoaded(user));
                return Promise.resolve(user);
            }).catch(err => {
                dispatch(fireShowAlert("Vaše informace se nepodařilo načíst", ALERT_DANGER, 10000));
                dispatch(userLoadingFailed(err));
                return Promise.reject(err);
            });
    };
}

export function fireLogout() {
    return (dispatch) => {
        window.sessionStorage.clear();
        window.location.replace(window.location.href);
        dispatch(userLogout())
    }
}
