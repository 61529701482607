/**
 * AUTH
 */
export const ADFS_AUTH_FAILED = "ADFS_AUTH_FAILED";
export const ADFS_ACCESS_EXPIRING = "ADFS_ACCESS_EXPIRING";
export const ADFS_ACCESS_EXPIRING_NOTED = "ADFS_ACCESS_EXPIRING_NOTED";
export const AUTH_INFO_LOADED = "AUTH_INFO_LOADED";
export const AUTH_INFO_FAILED = "AUTH_INFO_FAILED";
export const AUTH_INFO_LOADING = "AUTH_INFO_LOADING";
export const AUTH_INFO_INVALIDATE = "AUTH_INFO_INVALIDATE";
export const AUTH_REFRESHING = "AUTH_REFRESHING";
export const AUTH_REFRESHED = "AUTH_REFRESHED";
export const AUTH_REFRESH_FAILED = "AUTH_REFRESH_FAILED";

/**
 * ALERT
 */
export const ALERT_HIDE = "ALERT_HIDE";
export const ALERT_SHOW = "ALERT_SHOW";
export const ALERT_FLOATING_CLOSE_DURATION = 10000;
export const ALERT_SUCCESS = "success";
export const ALERT_WARNING = "warning";
export const ALERT_DANGER = "danger";
export const ALERT_INFO = "info";

/**
 * LOGGED USER
 */
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const USER_LOADING_FAILED = "USER_LOADING_FAILED";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_ROLES_EVALUATED = "USER_ROLES_EVALUATED";

/**
 * Environment
 */
export const ENVIRONMENT_PROPERTIES_ERROR = "ENVIRONMENT_PROPERTIES_ERROR";
export const ENVIRONMENT_PROPERTIES_LOADED = "ENVIRONMENT_PROPERTIES_LOADED";
export const ENVIRONMENT_PROPERTIES_LOADING = "ENVIRONMENT_PROPERTIES_LOADING";