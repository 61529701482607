import queryString from 'query-string';
import CaseTable from "./caseTable";
import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import FilterSearch from "./filterSearch";
import "../../App.css";

const MainPage = () => {
    const [activeFiltersFE, setActiveFiltersFE] = useState([]);
    const [activeFiltersBE, setActiveFiltersBE] = useState(["ACTIVE_CASES"]);
    const [parsedCluid, setParsedCluid] = useState(null);

    useEffect(() => {
        let {cluid} = queryString.parse(window.location.search);
        setParsedCluid(cluid);
    }, [parsedCluid]);


    const handleCheckFE = (event) => {
        let updatedList = [...activeFiltersFE];
        if (event.target.checked) {
            updatedList = [...activeFiltersFE, event.target.value];
        } else {
            updatedList.splice(activeFiltersFE.indexOf(event.target.value), 1);
        }
        setActiveFiltersFE(updatedList);
    };

    const handleCheckBE = (event) => {
        let updatedList = [...activeFiltersBE];
        if (event.target.checked) {
            updatedList = [...activeFiltersBE, event.target.value];
        } else {
            updatedList.splice(activeFiltersBE.indexOf(event.target.value), 1);
        }
        setActiveFiltersBE(updatedList);
    };

    return (
        <>
                <Row className="checkboxRow">
                    <Col>
                        <FilterSearch handleCheckFE={handleCheckFE} handleCheckBE={handleCheckBE}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CaseTable cluid={parsedCluid}
                                   activeFiltersFE={activeFiltersFE}
                                   activeFiltersBE={activeFiltersBE}/>
                    </Col>
                </Row>
        </>
    );
};

export default MainPage;