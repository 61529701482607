import React from "react";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DialogInfo = ({isOpen, color, icon, onSubmit, onToggle, header, info, submitLabel, toggleLabel = "Zavřít"}) => {

    return (
        <Modal isOpen={isOpen} className={color}>
            <ModalHeader>
                <Row>
                    <Col className="col-md-1">
                      <FontAwesomeIcon icon={icon}/>
                    </Col>
                    <Col className="my-auto">
                        <span className="font-weight-bold font-size-12">{header}</span>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody className="py-0">
                <Row className="mt-3 mb-3">
                    <Col className="offset-md-1 font-size-12">
                        {info}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {onToggle ?
                    <Button color={color} outline onClick={onToggle}>{toggleLabel}</Button> : null}

                {onSubmit ?
                    <Button color={color} onClick={onSubmit}>{submitLabel}</Button> : null}
            </ModalFooter>
        </Modal>
    )
};

export default DialogInfo;