import React from "react";
import dayjs from "dayjs";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import TooltipIcon from "./tooltipicon";
import Resource from "../../core/serverResource";

const CaseTableItem = ({caseItem}) => {

    //TODO mapping and values
    const PRODUCT_CODE = {
        "JPP_CTG_001": 'Účet',
        "JPP_CTG_002": 'Spoření',
        "JPP_CTG_003": 'Úvěr',
        "JPP_CTG_004": 'Pojištění',
        "JPP_CTG_005": 'Investice',
        "JPP_CTG_006": 'Služby',
        "JPP_CTG_007": 'Karta debetní',
        "JPP_CTG_008": 'Karta kreditní',
        "JPP_CTG_009": 'Služba k produktu',
        "JPP_CTG_010": 'Kontokorent',
        "JPP_CTG_011": 'Investiční pozice',
        "JPP_CTG_012": 'Nefinanční produkt',
        "JPP_CTG_013": 'Skupinové pojištění',
        "JPP_CTG_014": 'Služba k produktu - pojištění',
        "JPP_CTG_015": 'Benefit',
    };

    //TODO mapping and values
    const CASE_STATES = {
        "NEW": "Nový",
        "WAITING": "Čekající",
        "ACTIVATED": "Zpracováván",
        "ACTIVE": "Zpracováván",
        "CANCELLED": "Stornovaný",
        "PROCESSED": "Dokončený",
        "FINISHED": "Dokončený",
        "NOTACTIVE": "Neaktivní",
        "EXPIRED": "Expirovaný",
        "CLOSED": "Uzavřen",
        "REJECTED": 'Zamítnutý',
        //BS-stuff
        "DRAFT": "Sběr dat OP",
        "CONTRACTING": "Podpis dokumentace",
        "VALIDATING": "Validace OP",
        "REVOKED": "Revokován",
        //accman stuff
        "INPROCESSING": "Aktivní"
    };

    //TODO mapping and values
    const NEW = ["NEW"];
    const ACTIVE = ["ACTIVE", "ACTIVATED", "DRAFT", "CONTRACTING", "INPROCESSING"];
    const WAITING = ["WAITING"];
    const PROCESSED = ["PROCESSED", "CLOSED", "FINISHED", "REVOKED"];
    const CANCELLED = ["REJECTED", "CANCELLED", "EXPIRED", "NOTACTIVE"];

    const {
        createdBy,
        caseName,
        caseState,
        cidla,
        createdAt,
        /*        channel,
                estimatedCompletionDate,*/
        product/*,
        canDelegate,
        urgencyType,
        maxTaskPriority,
        overviewUrl*/
    } = caseItem;

    const createEccCaseURL = () => {
        let baseURL = new URL(Resource.getEccUrl());
        if (getCaseId()) {
            baseURL.searchParams.set('caseId', getCaseId());
        }
        baseURL.searchParams.set('detail', 'true');
        return baseURL;
    };

    const renderEccCaseDescription = () => {
        if (caseItem.linkToEcc) {
            return <a className="caseTableInternalUrl" rel="noopener noreferrer" target="_blank"
                      href={createEccCaseURL()}>{caseName} ({getCaseId()})</a>;
        } else {
            return caseName + " (" + getCaseId() + ")";
        }
    };

    const renderClient = () => {
        let result = "";
        if (caseItem.person) {
            result = caseItem.person.fullName + ', ' + caseItem.person.personalId;
        } else if (caseItem.organization) {
            result = caseItem.organization.name + ' ' + caseItem.organization.registrationNumber;
        }

        return result;
    };

    const renderProduct = () => {
        let result = [];
        if (product) {
            if (product.finProductId) {
                result.push(PRODUCT_CODE[product.finProductId]);
            }
            if (product.account) {
                result.push(product.account.prefix + "-" + product.account.account + "/" + product.account.bankCode);
            } else if (product.agreementNumber) {
                result.push(product.agreementNumber)
            }
        }
        return result.join(" ");
    };

    const renderSalesChannel = () => {
        let result;

        if (caseItem.channelTranslation) {
            result = caseItem.channelTranslation;
        } else if (caseItem.channel) {
            result = caseItem.channel;
        } else {
            result = "není k dispozici";
        }

        return result;
    };

    const renderCreatedAt = () => {
        if (createdAt) {
            return (
                <tr>
                    <td className="unimportant">založení:</td>
                    <td>{dayjs(createdAt).format('D.M.YYYY')}</td>
                </tr>
            );
        } else {
            return (
                <tr>
                    <td className="unimportant">založení:</td>
                    <td className="unimportant noWrap">není k dispozici</td>
                </tr>
            )
        }
    };

    const renderCreator = () => {
        if (createdBy) {
            return (
                <tr>
                    <td className="unimportant">zadavatel:</td>
                    <td className="noWrap">{createUserName(createdBy)}</td>
                </tr>
            )
        } else {
            return (
                <tr>
                    <td className="unimportant">zadavatel:</td>
                    <td className="unimportant noWrap">není k dispozici</td>
                </tr>
            )
        }
    }

    const getCaseId = () => {
        const {systemId, applicationNumber} = caseItem;

        if (systemId && (["BRASIL", "CPS"].includes(systemId))) {
            return applicationNumber;
        }
        return cidla;
    };

    const createUserName = (currentOwner) => {
        if (!currentOwner) {
            return;
        }
        if (currentOwner.firstName && currentOwner.surname) {
            return currentOwner.firstName + " " + currentOwner.surname + " (" + currentOwner.username + ")";
        } else if (currentOwner.username) {
            return currentOwner.username;
        }
    };

    const findBadgeColor = () => {
        if (caseState) {
            let state = caseState.toUpperCase();
            if (NEW.includes(state)) {
                return "caseNew";
            } else if (WAITING.includes(state)) {
                return "caseWaiting";
            } else if (ACTIVE.includes(state)) {
                return "caseActivated";
            } else if (PROCESSED.includes(state)) {
                return "caseProcessed"
            } else if (CANCELLED.includes(state)) {
                return "caseCancelled"
            }
        }
    };

    const translateCaseStatus = () => {
        return CASE_STATES[caseState?.toUpperCase()];
    };

    const getCaseState = () => {
        return <div
            className={`crm caseState ${findBadgeColor()}`}>{translateCaseStatus()}</div>;
    };

    const renderCurrentOwner = () => {
        if (caseItem.currentOwner) {
            return (
                <tr>
                    <td className="unimportant">řešitel:</td>
                    <td className="noWrap">{createUserName(caseItem.currentOwner)}&nbsp;
                        <TooltipIcon id={'tooltip2' + getCaseId()} color="#7997B2" icon={faInfoCircle}
                                     message={"Poslední úprava: " + (caseItem.modifiedAt ? dayjs(caseItem.modifiedAt).format('D.M.YYYY') : dayjs(createdAt).format('D.M.YYYY'))}/>
                    </td>
                </tr>
            )
        } else {
            return (
                <tr>
                    <td className="unimportant">řešitel:</td>
                    <td className="unimportant noWrap">není k dispozici</td>
                </tr>
            )
        }
    }

    return (
        <>
            <tr className="caseTableRow">
                <td width="5%">{getCaseState()}</td>
                <td width="30%" className="ellipsis">{renderEccCaseDescription()}</td>
                <td width="23%" className="ellipsis">
                    <div className="ellipsisContent" title={renderClient()}>{renderClient()}</div>
                    <div className="ellipsisContent"
                         title={renderProduct()}>{renderProduct()}</div>
                </td>
                <td width="7%">{renderSalesChannel()}</td>
                <td width="15%" className="tooltip-on-same-line">
                    <table className="crm crmCasesInlineTable">
                        <tbody>
                        {renderCreatedAt()}
                        </tbody>
                    </table>
                </td>
                <td width="20%">
                    <table className="crm crmCasesInlineTable">
                        <tbody>
                        {renderCreator()}
                        {renderCurrentOwner()}
                        </tbody>
                    </table>
                </td>
            </tr>
        </>
    );
};

export default CaseTableItem;