import React, {useEffect, useState} from "react";
import "../../App.css";
import {Col, Container, Row, Spinner, Table} from "reactstrap";
import CaseTableItem from "./caseTableItem";
import PagingNavigator from "./pagingNavigator";
import {searchClientDashboard} from "../../actions/searchActions";

const CaseTable = ({cluid, activeFiltersFE, activeFiltersBE}) => {
    const PAGE_SIZE = 5;
    const [loading, setLoading] = useState(false);
    const [page, setCurrentPage] = useState(1);
    const [cases, setCases] = useState([]);
    const [filteredCases, setFilteredCases] = useState([]);

    useEffect(() => {
        if (cluid) {
            //TODO loading cases animation
            setLoading(true);
            searchClientDashboard(cluid, activeFiltersBE).then(c => {
                setCases(c);
                setLoading(false);
            });
        }
    }, [cluid, activeFiltersBE]);

    useEffect(() => {
        if (cluid) {
            setCurrentPage(1);
            setFilteredCases(evaluateFeFilters());
        }
    }, [cases, activeFiltersFE]);

    const evaluateFeFilters = () => {
        let caseCopy = cases.map(c => {
            return {...c}
        });
        if (activeFiltersFE.includes("MY_TEAM_ONLY")) {
            caseCopy = caseCopy.filter(c => c.linkToEcc === true);
        }
        return caseCopy;
    }

    const calculateFirstItem = () => {
        return (page - 1) * PAGE_SIZE;
    }

    const calculateLastItem = () => {
        return Math.min(page * PAGE_SIZE, filteredCases.length);
    }

    const getMaxPage = () => {
        return Math.ceil(filteredCases.length / PAGE_SIZE);
    }

    const handleFastBackwardClick = () => {
        setCurrentPage(1);
    };

    const handleFastForwardClick = () => {
        setCurrentPage(getMaxPage());
    }

    const handleStepForwardClick = () => {
        setCurrentPage(Math.min(page + 1, getMaxPage()));
    }

    const handleStepBackwardClick = () => {
        setCurrentPage(Math.max(page - 1, 1));
    }

    const getCaseSlice = () => {
        if (filteredCases?.length) {
            return filteredCases.slice(calculateFirstItem(), calculateLastItem());
        } else {
            return [];
        }
    }

    return (
        <>
            <Container fluid>
                <Row style={{verticalAlign: "bottom"}}>
                    <Col md="8">&nbsp;</Col>
                    <Col md="4" id="caseTableOrderingAndPageInfo">
                        <span
                            id="caseTablePageNumber">Zobrazeno <b>{calculateFirstItem() + 1} - {calculateLastItem()}</b> z <b>{filteredCases.length}</b>&nbsp;
                            {/*                            {!lastDataChunk ? <TooltipIcon id="lastDataChunkInfo" icon={faInfoCircle}
    message="Seznam vyhledaných požadavků je omezen na maximálně 300 záznamů. Pro přístup k dalším záznamům upřesněte vyhledávací parametry."/> : null}*/}
                    </span>
                    </Col>
                </Row>
                <Row>
                    <Col><Table size="sm" borderless className="crm">
                        <thead className="caseTableHeader">
                        <tr>
                            <th>Stav</th>
                            <th>Popis</th>
                            <th>Klient/Produkt</th>
                            <th>Prodejní kanál</th>
                            <th>Data</th>
                            <th>Role</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!loading && getCaseSlice().map((c) => (<CaseTableItem key={c.cidla} caseItem={c}/>))}
                        </tbody>
                    </Table>
                    </Col>
                </Row>
                <Row>
                    {loading && <Col style={{textAlign: "center"}}><Spinner className="crmSpinner"/></Col>}
                </Row>
                {filteredCases.length > PAGE_SIZE ?
                    <Row>
                        <Col style={{textAlign: "center"}}>
                            <PagingNavigator
                                handleFastBackwardClick={handleFastBackwardClick}
                                handleStepBackwardClick={handleStepBackwardClick}
                                handleStepForwardClick={handleStepForwardClick}
                                handleFastForwardClick={handleFastForwardClick}
                            />
                        </Col>
                    </Row> : null}
            </Container>
        </>
    );
};

export default CaseTable;