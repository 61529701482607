import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {Alert, Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

const Prompt = () => {

    const openInWindow = () => {
        let result = window.open(window.location.href, "_blank");
    };

    return (
        <Row className="m-4">
            <Col md={6} className="offset-3">
                <Alert color="info" className="p-5 text-center">
                    <span className="d-block text-center">
                        <FontAwesomeIcon icon={faSearch} size="4x" className="p-2"/>
                    </span>
                    <span className="d-block">Pro zobrazení požadavků vybraného klienta prosím klikněte na tlačítko.</span>
                    <Button onClick={openInWindow} color="info" className="m-2">
                        Zobrazit požadavky
                    </Button>
                </Alert>
            </Col>
        </Row>
    )
}

export default Prompt;