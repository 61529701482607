import { enableES5 } from 'immer';
import { combineReducers } from "redux";
import authorization from "./authorization";
import user from "./user";
import alerts from "./alerts";
import environment from "./environment";

enableES5();

const rootReducer = combineReducers({
    authorization,
    user,
    alerts,
    environment
  });

  export default rootReducer;
