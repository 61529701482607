import Resource from "../core/serverResource";

export function searchClientDashboard(cluid, activeBEFilters) {
        // Create BE req
        const query = {
            cluid: cluid,
            activeFilters: activeBEFilters
        };
        return fetch(Resource.getFocupApi() + "/cases", {
            headers: {
                'Content-Type': 'application/json'
            }, method: "POST", body: JSON.stringify(query)
        })
            .then(response => response.json())
            .then(casesResponse => {
                return Promise.resolve(casesResponse.cases);
            })
            .catch(err => {
                console.error("Unable to load cases");
                return Promise.reject(err);
            })
}


